export const translationStrings = {
    screenReaderNav: Drupal.t('Main navigation'),
    pumpenfinder: Drupal.t('Pumpenfinder'),
    contact: Drupal.t('Kontakt'),
    overview: Drupal.t('Übersicht'),
    productFinderH1: Drupal.t('Produktübersicht'),
    productFinderH2: Drupal.t('Finden Sie die passende Pumpe mithilfe unseres einzigartigen Pumpenfinders'),
    productFinderH3: Drupal.t('Finden Sie Ihre Pumpe!'),
    productFinderSpan: Drupal.t('Einfach Eigenschaften auswählen und nach unten scrollen – dann sehen Sie, welche SCHMITT-Pumpen perfekt zu Ihren Anforderungen passen.'),
    productFinderTagTooltip: Drupal.t('Zu dieser Auswahl gibt es keine passenden Produkte.'),
    productOverviewPrefix: Drupal.t('In unserer '),
    productOverviewSuffix: Drupal.t(' finden Sie alle relevanten technischen Daten sowie Inhalte in einem Dokument zusammengefasst.'),
    productGroups: {
        horizontalPlastic: Drupal.t('Horizontale Kreiselpumpen aus Kunststoff'),
        horizontalSteel: Drupal.t('Horizontale Kreiselpumpen aus Edelstahl'),
        verticalPlastic: Drupal.t('Vertikale Kreiselpumpen aus Kunststoff'),
        verticalSteel: Drupal.t('Vertikale Kreiselpumpen aus Edelstahl'),
    },
    armaturesValvesGroups: {
        armatures: Drupal.t('Armaturen'),
        valves: Drupal.t('Ventile'),
        armaturesAndValves: Drupal.t('Armaturen & Ventile')
    },
    requestPump: Drupal.t('Pumpe anfragen'),
    requestValve: Drupal.t('Hahn anfragen'),
    learnMore: Drupal.t('Mehr erfahren'),
};
