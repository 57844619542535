<template>
    <div class="product-finder__tags">
        <transition name="fade">
            <div v-show="showTooltip"
                class="product-finder__tooltip"
                :style="{ opacity: showTooltip ? 1 : 0 }">
                {{ t.productFinderTagTooltip }}
            </div>
        </transition>
        <div class="tag"
            :class="{
                'tag--active': value.includes(tag),
                'tag--inactive': value.length > 0 && !value.includes(tag),
                'tag--disabled': tag.disabled
            }"
            v-for="tag in tags"
            :key="tag.id"
            @click="toggleSelection(tag)"
            @mouseenter="showTooltip = tag.disabled && !isMobile"
            @mouseleave="showTooltip = false">
            {{ tag.name }}
        </div>
    </div>
</template>

<script>
// Helpers
import { isMobile } from '../helpers/is-mobile';
import { translationStrings } from '../translation-strings';

export default {
    name: 'product-finder-tags',
    props: {
        value: {
            type: Array,
            required: true,
        }, // v-model
        tags: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            isMobile: isMobile(),
            showTooltip: false,
            t: translationStrings,
        };
    },
    methods: {
        toggleSelection(tag) {
            if (tag.disabled) {
                // tooltip on mobile should disappear after 1.5s
                if (this.isMobile) {
                    this.showTooltip = true;
                    setTimeout(() => {
                        this.showTooltip = false;
                    }, 1500);
                }
            } else {
                if (this.value.includes(tag)) {
                    this.$emit(
                        'input',
                        this.value.filter((item) => item !== tag)
                    );
                } else {
                    this.value.push(tag);
                    this.$emit('input', this.value);
                }
            }
        },
    }
};
</script>
