import { isMobile } from './helpers/is-mobile';
import anime from 'animejs/lib/anime.es.js';

const init = (element) => {
    const lightbox = element.querySelector('.lightbox');
    let isOpen = false;

    const toggleLightbox = () => {

        if (!isOpen) {
            anime({
                targets: lightbox,
                opacity: 1,
                begin: () => {
                    lightbox.style.display = 'flex';
                },
                complete: () => {
                    isOpen = true;
                },
            });
        } else {
            anime({
                targets: lightbox,
                opacity: 0,
                duration: 100,
                complete: () => {
                    lightbox.style.display = 'none';
                    isOpen = false;
                },
            });
        }
    };

    element.addEventListener('click', toggleLightbox);

};

(() => {
    // Init only on Model Series nodes with a performance chart (max 1 per node)
    const trigger = document.getElementById('lightbox-trigger');

    // Attach handler to all accordions
    if (trigger && !isMobile()) {
        init(trigger);
    }
})();

