// 3rd
import anime from 'animejs/lib/anime.es.js';

export const init = (els) => {
    els.forEach(el => {
        el.addEventListener('click', () => {
            // If a target element was specified scroll it into view
            // else – scroll to page top
            let target = 0;

            if (el.dataset.scrollTo) {
                const targetEl = document.querySelector(`${el.dataset.scrollTo}`);
                const header = document.querySelector('#header');
                target = Math.round(targetEl.getBoundingClientRect().top + window.pageYOffset) - header.clientHeight;
            }

            anime({
                targets: [document.documentElement, document.body],
                scrollTop: target,
                duration: 1000,
                easing: 'cubicBezier(0.010, 0.745, 0.295, 0.995)',
            });
        })
    })
};

(() => {
    const triggers = Array.from(document.querySelectorAll('[data-scroll-to]'));
    if (triggers.length) {
        init(triggers);
    }
})();
