<template>
    <div class="product-finder">
        <div v-if="!forFittingsValves" class="product-finder__title-area">
            <div class="container">
                <!-- Breadcrumbs slot -->
                <slot></slot>
                <div class="row">
                    <div class="column">
                        <h1>{{ t.productFinderH1.toUpperCase() }}</h1>
                        <h2>{{ t.productFinderH2 }}</h2>
                        <!-- Will have h2 styling from M to XL -->
                        <h3>{{ t.productFinderH3 }}</h3>
                        <span>{{ t.productFinderSpan }}</span>
                        <!-- Tag-based product filter -->
                        <product-finder-tags
                            :tags="tags"
                            v-model="activeTags"
                        ></product-finder-tags>
                    </div>
                    <div class="column">
                        <div v-if="loading" class="loader"></div>
                        <!-- SVG graph -->
                        <product-finder-svg v-else
                            :svg-data="svgData"
                            :tags="buildIdsArray(activeTags)"/>
                        <!-- Product overview text -->
                        <p>
                            {{ t.productOverviewPrefix }}
                            <a :href="pdf" target="_blank" class="download">
                                {{ t.productFinderH1 }}
                            </a>
                            {{ t.productOverviewSuffix }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="product-finder__results">
            <div class="container">
                <div v-if="loading" class="loader"></div>
                <template v-else>
                    <template v-if="!forFittingsValves">
                        <!-- Product groups -->
                        <div class="product-finder__product-group"
                            v-if="horizontalPlastic.length">
                            <h2>
                                {{ t.productGroups.horizontalPlastic }}
                            </h2>
                            <product-teaser
                                v-for="product in horizontalPlastic"
                                :product="product"
                                :key="product.id" />
                        </div>
                        <div class="product-finder__product-group"
                            v-if="horizontalSteel.length">
                            <h2>
                                {{ t.productGroups.horizontalSteel }}
                            </h2>
                            <product-teaser
                                v-for="product in horizontalSteel"
                                :product="product"
                                :key="product.id" />
                        </div>
                        <div class="product-finder__product-group"
                            v-if="verticalPlastic.length">
                            <h2>
                                {{ t.productGroups.verticalPlastic }}
                            </h2>
                            <product-teaser
                                v-for="product in verticalPlastic"
                                :product="product"
                                :key="product.id" />
                        </div>
                        <div class="product-finder__product-group"
                            v-if="verticalSteel.length">
                            <h2>
                                {{ t.productGroups.verticalSteel }}
                            </h2>
                            <product-teaser
                                v-for="product in verticalSteel"
                                :product="product"
                                :key="product.id" />
                        </div>
                    </template>
                    <template v-else>
                        <!-- On the fittings/valves page we have different categories. -->
                        <div class="product-finder__product-group"
                            v-if="armaturesAndValves.length">
                            <h2>
                                {{ t.armaturesValvesGroups.armaturesAndValves }}
                            </h2>
                            <product-teaser
                                v-for="product in armaturesAndValves"
                                :product="product"
                                :button-text="t.requestValve"
                                :scroll-to-webform="forFittingsValves"
                                :key="product.id" />
                        </div>
                    </template>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
// Helpers
import { translationStrings } from '../translation-strings';
import { getUniqueItemsByKey } from '../helpers/filter-unique';

// 3rd
import axios from 'axios';

// Components
import ProductFinderSvg from './ProductFinderSvg';
import ProductFinderTags from './ProductFinderTags';
import ProductTeaser from './ProductTeaser';

export default {
    name: 'product-finder',
    components: {
        ProductFinderSvg,
        ProductFinderTags,
        ProductTeaser,
    },
    props: {
        lang: {
            type: String,
            required: true,
        },
        tags: {
            type: Array,
            required: true,
        },
        pdf: {
            type: String,
            required: true,
        },
        forFittingsValves: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loading: true,
            items: [],
            activeTags: [],
            svgData: {},
            t: translationStrings,
        };
    },
    computed: {
        filteredResults() {
            if (this.activeTags.length) {
                return this.items.filter((item) => {
                    return this.activeTags.every((activeTag) =>
                        item.tags.some((tag) => tag.id === activeTag.id)
                    );
                });
            } else {
                return this.items;
            }
        },
        // Product grouping @todo: use sth else as id?
        // (/term/id can change if term is deleted and re-created)
        horizontalPlastic() {
            return !this.forFittingsValves
                ? this.filteredResults.filter((item) => {
                      return ['4', '6'].every((id) =>
                          item.tags.some((tag) => tag.id === id)
                      );
                  })
                : [];
        },
        horizontalSteel() {
            return !this.forFittingsValves
                ? this.filteredResults.filter((item) => {
                      return ['5', '6'].every((id) =>
                          item.tags.some((tag) => tag.id === id)
                      );
                  })
                : [];
        },
        verticalPlastic() {
            return !this.forFittingsValves
                ? this.filteredResults.filter((item) => {
                      return ['4', '7'].every((id) =>
                          item.tags.some((tag) => tag.id === id)
                      );
                  })
                : [];
        },
        verticalSteel() {
            return !this.forFittingsValves
                ? this.filteredResults.filter((item) => {
                      return ['5', '7'].every((id) =>
                          item.tags.some((tag) => tag.id === id)
                      );
                  })
                : [];
        },
        // Grouping of fittings/valves
        // (at the moment there is only one category)
        armaturesAndValves() {
            return this.forFittingsValves
                ? this.filteredResults.filter((item) => item.category)
                : [];
        }
    },
    watch: {
        // Change pump img opacity by altering svgData prop
        filteredResults(items) {
            Object.keys(this.svgData).forEach((key) => {
                this.$set(
                    this.svgData[key],
                    'active',
                    // Returns true if pump id is among the active results.
                    this.filteredResults.some((result) => result.id === key)
                );
            });

            // Tags that are not available in current results should be disabled.
            let availableTags = [];
            items.forEach((item) => {
                const items = !this.forFittingsValves ? item.tags : item.category;
                availableTags.push(...items);
            });

            availableTags = getUniqueItemsByKey(availableTags, 'id');
            this.tags.forEach((tag, key) => {
                this.$set(
                    this.tags[key],
                    'disabled',
                    !availableTags.some((item) => item.id === tag.id)
                );
            });
        },
    },
    mounted() {
        this.fetchProducts();
    },
    methods: {
        fetchProducts() {
            const url = this.forFittingsValves
                ? '/api/fittings_valves'
                : '/api/products';

            axios({
                method: 'get',
                url: url,
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                // Get items only with ids and in the current lang.
                this.items = response.data.filter(
                    (item) =>
                        (item.id || this.forFittingsValves) &&
                        item.lang === this.lang
                );
                // Set predefined filters if any.
                this.setPredefinedFilters();
                // Map pumps for SVG manipulation, Pump finder only.
                if (!this.forFittingsValves) {
                    this.buildSvgData(this.items);
                }
                this.loading = false;
            });
        },
        setPredefinedFilters() {
            // @todo: ^ again term ids..
            if (window.location.pathname.match(/horizontal/gi)) {
                this.activeTags.push(this.tags.find((tag) => tag.id === '6'));
            }
            if (window.location.pathname.match(/vertical|vertikal/gi)) {
                this.activeTags.push(this.tags.find((tag) => tag.id === '7'));
            }
            if (window.location.hash.match(/kunststoff|non-metallic/gi)) {
                this.activeTags.push(this.tags.find((tag) => tag.id === '4'));
            }
            if (window.location.hash.match(/edelstahl|stainless-steel/gi)) {
                this.activeTags.push(this.tags.find((tag) => tag.id === '5'));
            }
        },
        buildSvgData(products) {
            // Map product ids to their URLs (for hyperlinks in SVG).
            this.svgData = products.reduce((o, key) => {
                const tags = key.tags.map((t) => +t.id);
                return {
                    ...o,
                    [key.id]: {
                        neolution: !!key.neolution,
                        tags: tags,
                        url: key.url,
                    },
                };
            }, {});
        },
        buildIdsArray(tags) {
            return tags.map((t) => +t.id);
        },
    },
};
</script>
