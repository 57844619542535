// Place data filtering helper functions here
export const getUniqueItems = (items, sorted = false) => {
    if (sorted) {
        return [...new Set(items)].sort((a, b) => a.localeCompare(b));
    }

    return [...new Set(items)];
};

// For object arrays: note that we can't just use return [...new Set(items)] because Set stores a unique reference to each object
// therefore it would treat objects with identical values as unique even though they aren't
export const getUniqueItemsByKey = (items, key) => {
    let lookup = new Set();
    return items.filter(item => !lookup.has(item[key]) && lookup.add(item[key]));
};
