<template>
    <nav role="navigation" class="navigation">
        <!-- Provide label for screen readers. -->
        <h2 class="visually-hidden">{{ translationStrings.screenReaderNav }}</h2>

        <!-- Hamburger button -->
        <div class="main-menu__mobile-trigger" v-if="isMobile" @click="toggleMobileNav"></div>

        <div class="main-menu__wrapper" :class="{ 'main-menu__wrapper--open': isOpen && isMobile }">
            <div class="quicklinks quicklinks--mobile" v-if="isMobile">
                <!-- Close button -->
                <div class="button button--close" @click="toggleMobileNav"></div>
                <!-- Mobile quicklinks -->
                <a :href="productsUrl" class="button button--primary">{{ translationStrings.pumpenfinder }}</a>
                <a :href="contactUrl" class="button button--secondary">{{ translationStrings.contact }}</a>
            </div>

            <ul class="main-menu">
                <li class="main-menu__item" :class="{ 'main-menu__item--active': item.in_active_trail || !item.is_collapsed }"
                    v-for="(item, index) in menuItems"
                    :key="item.title"
                >
                    <a :href="item.url" @click="openDropdown($event, item, index)">{{ item.title }}</a>
                    <template v-if="item.is_expanded">
                        <div class="submenu__wrapper">
                            <!-- Accordion behavior on mobile -->
                            <ul class="submenu" v-if="isMobile">
                                <li> <!-- Overview link -->
                                    <a :href="item.url">{{ translationStrings.overview }}</a>
                                </li>
                                <li class="submenu__item" v-for="subitem in item.below">
                                    <a :href="subitem.url" :target="subitem.target">{{ subitem.title }}</a>
                                </li>
                            </ul>
                            <template v-else>
                                <!-- Mega dropdown on desktop -->
                                <div class="mega-dropdown">
                                    <div class="mega-dropdown__head">
                                        <!-- Overview link -->
                                        <a :href="item.url">{{ translationStrings.overview }}</a>
                                        <div class="button button--close" @click="closeAll"></div>
                                    </div>
                                    <div class="mega-dropdown__body">
                                        <ul class="submenu">
                                            <li class="submenu__item" v-for="subitem in item.below">
                                                <a :href="subitem.url" :target="subitem.target">{{ subitem.title }}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </template>
                </li>
            </ul>
        </div>

        <!-- Overlay -->
        <transition name="fade">
            <div class="main-menu__overlay" v-show="isOpen" @click="closeAll"></div>
        </transition>
    </nav>
</template>

<script>
    // Helpers
    import { isMobile } from '../helpers/is-mobile';
    import scrollLock from '../helpers/scroll-lock';
    import { translationStrings } from '../translation-strings';

    export default {
        name: 'main-menu',
        props: {
            mainMenu: {
                required: true,
            },
            productsUrl: {
                type: String,
                required: true,
            },
            contactUrl: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                isMobile: isMobile(),
                isOpen: false,
                translationStrings: translationStrings,
                menuItems: this.mainMenu, // because props shouldn't be mutated directly
            }
        },
        mounted() {
            // Recycle Drupal's is_collapsed property for state mapping purposes
            Object.keys(this.menuItems).forEach((item) => {
                this.$set(this.menuItems[item], 'is_collapsed', true);
            });

            // Make nav doesn't break on resize
            window.addEventListener('resize', () => {
                this.isMobile = isMobile();
            });
        },
        methods: {
            toggleMobileNav() {
                this.isOpen = !this.isOpen;
                // Lock/unlock scrolling depending on state
                if (this.isOpen) {
                    scrollLock.lock();
                } else {
                    scrollLock.unlock();
                }
            },

            openDropdown(e, item, index) {
                // If item has a dropdown
                if (item.is_expanded) {

                    e.preventDefault();

                    const dropdown = e.target.parentNode.querySelector('.submenu__wrapper');
                    const dropdownHeight = dropdown.scrollHeight;

                    if (item.is_collapsed) {
                        // Close all open dropdowns
                        this.closeAll();
                        // Open target dropdown
                        this.$set(this.menuItems[index], 'is_collapsed', false);
                        dropdown.style.height = `${dropdownHeight}px`;
                        if (!this.isMobile) {
                            this.isOpen = true;
                        }
                    }
                    else {
                        // Close target dropdown if open
                        this.$set(this.menuItems[index], 'is_collapsed', true);
                        dropdown.style.height = '0';
                        if (!this.isMobile) {
                            this.isOpen = false;
                        }
                    }
                }
                else {
                    // If item has no dropdown, carry on to linked page
                    return true;
                }
            },

            closeAll() {
                const allDropdowns = Array.from(this.$el.querySelectorAll('.submenu__wrapper'));

                allDropdowns.forEach((el) => {
                    el.style.height = '0';
                });
                Object.keys(this.menuItems).forEach((menuItem) => {
                    this.$set(this.menuItems[menuItem], 'is_collapsed', true);
                });

                if (!this.isMobile) {
                    this.isOpen = false;
                }
            },
        },
    };
</script>
