<template>
    <div class="product-teaser"
        :class="{ 'product-teaser--neolution' : !!parseInt(product.neolution) }">
        <a :href="product.url" class="product-teaser__image">
            <img :src="product.image" :alt="product.title" />
        </a>
        <div class="product-teaser__details">
            <h3>{{ product.title }}</h3>
            <p>{{ product.description }}</p>
        </div>
        <div class="product-teaser__actions">
            <a v-if="!scrollToWebform"
               href="https://forms.office.com/pages/responsepage.aspx?id=WvehS3YYiUqiYEdkcdldbnzWuAnQdehLthYwW-gZ9V1UQUdUVkUwME5DWVFWRjVLMTlQSU5PM1JPWC4u&embed=true"
               target="_blank"
               class="button button--primary">
                {{ buttonText }}
            </a>
            <div v-else data-scroll-to="#pump-request" class="button button--primary">
                {{ buttonText }}
            </div>
            <a :href="product.url" class="button button--secondary">
                {{ translationStrings.learnMore }}
            </a>
        </div>
    </div>
</template>

<script>
// Helpers
import { translationStrings } from '../translation-strings';
import { init } from '../helpers/scroll-to';

export default {
    name: 'product-teaser',
    props: {
        product: {
            type: Object,
            required: true
        },
        buttonText: {
            type: String,
            default: translationStrings.requestPump
        },
        scrollToWebform: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            translationStrings: translationStrings
        };
    },
    mounted() {
        init(this.$el.querySelectorAll('[data-scroll-to]'));
    },
};
</script>
