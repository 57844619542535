const handleClick = (item, allItems) => {
  let expanded = item.classList.contains('accordion-item--open');
  const expandableElement = item.querySelector('.accordion-item__body');

  if(!expanded) {
    // Expand target accordion
    expandableElement.style.height = `${expandableElement.scrollHeight}px`;
    expandableElement.style.opacity = '1';
    item.classList.toggle('accordion-item--open');
  } else {
    // Collapse target accordion
    expandableElement.style.height = '0';
    expandableElement.style.opacity = '0';
    item.classList.toggle('accordion-item--open');
  }
};

(() => {
  // Get all accordions
  const accordions = Array.from(document.querySelectorAll('.accordion-item'));

  // Attach handler to all accordions
  if (accordions.length) {
    accordions.forEach((item) => {
      const trigger = item.querySelector('.accordion-item__head');
      trigger.addEventListener('click', () => handleClick(item, accordions));
    });
  }
})();

