// Helpers
import { isMobile } from '../helpers/is-mobile';
import anime from 'animejs/lib/anime.es.js';

const init = (target) => {
    let isExpanded = false;
    const tabsMenu = target.querySelector('.tabs-menu');
    const tabsMenuActiveTab = target.querySelector('.tabs-menu__active-tab');
    const tabsMenuItems = Array.from(target.querySelectorAll('.tabs-menu__item'));
    const tabItems = Array.from(target.querySelectorAll('.tab-item'));

    const toggleTabsMenu = () => {
        // Accordion-like behavior only on mobile
        if (isMobile()) {
            const actualHeight = tabsMenu.scrollHeight;
            isExpanded = tabsMenuActiveTab.classList.contains('tabs-menu__active-tab--expanded');

            if (!isExpanded) {
                tabsMenuActiveTab.classList.add('tabs-menu__active-tab--expanded');
                tabsMenu.style.height = `${actualHeight}px`;
            } else {
                tabsMenuActiveTab.classList.remove('tabs-menu__active-tab--expanded');
                tabsMenu.style.height = '0';
            }

            isExpanded = !isExpanded;
        }
    };

    const setActiveTab = (e) => {
        tabItems.forEach(tab => {
            anime({
                targets: tab,
                opacity: 0,
                translateY: -20,
                duration: 300,
                complete: () => {
                    tab.style.display = 'none';
                    document.querySelector(`#${e.target.dataset.tab}`).style.display = 'block';

                    anime({
                        targets: `#${e.target.dataset.tab}`,
                        opacity: 1,
                        translateY: 0,
                        duration: 400,
                    });
                },
            })
        });
        tabsMenuItems.forEach(item => item.classList.remove('tabs-menu__item--active'));
        e.target.classList.add('tabs-menu__item--active');
        tabsMenuActiveTab.textContent = e.target.textContent;

        // Close expanded menu after tab selection if on mobile
        if (isMobile()) {
            toggleTabsMenu();
        }
    };

    // Add click handlers
    tabsMenuActiveTab.addEventListener('click', () => toggleTabsMenu());
    tabsMenuItems.forEach(el => {
        el.addEventListener('click', setActiveTab);
    });

    // Set initial active tab
    tabsMenuItems[0].classList.add('tabs-menu__item--active');
    anime({
        targets: tabItems[0],
        opacity: 1,
        translateY: 0,
        duration: 400,
        begin: () => {
            tabItems[0].style.display = 'block';
        },
    });
};

(() => {
    // Check for tabs paragraphs
    const tabs = Array.from(document.querySelectorAll('.paragraph-tabs'));

    // Attach handler to each tabs paragraph
    if (tabs.length) {
        tabs.forEach((item) => {
            init(item);
        });
    }
})();
