import Vue from 'vue';

Vue.config.productionTip = false;

// Setup
import './scss/app.scss';

// trio assets
import TrioAssets from 'trio-assets-js';
(() => {
    /*TrioAssets.parallax();*/
    TrioAssets.responsiveImagePolyfill();
})();

// Scripts
import './js/paragraphs/accordion';
import './js/paragraphs/tabs';
import './js/helpers/scroll-to';
import './js/lightbox';
import './js/sidebar-widget';

// Components
import MainMenu from './js/components/MainMenu';
import ProductFinder from './js/components/ProductFinder';

// Register components
Vue.component('main-menu', MainMenu);
Vue.component('product-finder', ProductFinder);

// Vue instances
if (document.querySelectorAll('#header').length) { // #header is always there but still..
    new Vue({
        el: '#header',
        comments: true,
    });
}

if (document.querySelectorAll('#product-finder').length) { // Product finder
    new Vue({
        el: '#product-finder',
        comments: true,
    });
}

if (document.querySelectorAll('#schmitt-armaures-valves').length) { // Product finder
    new Vue({
        el: '#schmitt-armaures-valves',
        comments: true,
    });
}
