// Helpers
import { isMobile } from './helpers/is-mobile';

let lastScrollTop = 0;
let sidebarEl;

const registerScrollHandler = () => {
    window.addEventListener('scroll', e => {
        const scroll = window.pageYOffset || document.documentElement.scrollTop;

        if (scroll > lastScrollTop) {
            sidebarEl.classList.remove('sidebar-widget--visible-on-mobile');
        } else if (scroll < lastScrollTop) {
            sidebarEl.classList.add('sidebar-widget--visible-on-mobile');
        }

        lastScrollTop = scroll <= 0 ? 0 : scroll;
    });
};

(() => {
    if (isMobile()) {
        sidebarEl = document.getElementById('sidebar-widget');

        if (sidebarEl) {
            registerScrollHandler();
        }
    }
})();
